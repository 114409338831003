.text-h1 {
    text-align: center;
    font-size: 40px !important;
    font-weight: 700 !important;
    color: rgb(97, 97, 97);
}

.title-1 {
    font-size: 25px !important;
    font-weight: bold !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    background-color: rgb(116, 156, 240) !important;
    border-radius: 5px !important;
    border: 1px solid rgb(113, 147, 219) !important;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.2) !important;
    padding: 1px 5px !important;
    margin: 0 1.5px !important;
    display: inline-block !important;
    position: absolute;
    top: 0px;
}

.title-2 {
    font-size: 25px !important;
    font-weight: bold !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    background-color: rgb(75, 196, 101) !important;
    border-radius: 5px !important;
    border: 1px solid rgb(73, 187, 111) !important;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.2) !important;
    padding: 1px 5px !important;
    margin: 0 1.5px !important;
    display: inline-block !important;
    position: absolute;
    top: 0px;
}

.question-unreplied {
    line-height: 24px;
}

.question-user {
    line-height: 24px;
}

.question-time {
    font-size: 13px !important;
    color: rgb(136, 136, 136) !important;
    padding-top: 10px;
    padding: 5px 55px 5px 55px;
}

.question {
    font-size: 16px !important;
    font-weight: bold !important;
    color: rgb(230, 77, 77) !important;
    padding-left: 35px;
    padding-right: 25%;
}

.question-target {
    display: flex;
    justify-content: right;
    padding-right: 35px;
}

.reply-user {
    line-height: 24px;
}

.reply-time {
    font-size: 13px !important;
    color: rgb(136, 136, 136) !important;
    padding: 5px 55px 5px 55px;
}

.reply {
    display: flex;
    justify-content: right;
    font-size: 16px !important;
    font-weight: bold !important;
    color: rgb(116, 153, 221) !important;
    padding-left: 25%;
    padding-right: 35px;
}

.no-reply {
    text-align: center;
    font-size: 15px !important;
    color: rgb(126, 165, 224) !important;
    padding: 10px 55px 5px 55px;
}

.no-record {
    text-align: center;
    font-size: 15px !important;
    font-weight: bold !important;
    color: rgb(224, 126, 126) !important;
    padding: 20px 55px 5px 55px;
}

.no-choose {
    text-align: center;
    font-size: 15px !important;
    color: rgb(209, 0, 0) !important;
    padding: 20px 55px 5px 55px;
}