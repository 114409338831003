.header {
  background-color: #1f1f1f !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-height: 64px;
}

.footer {
  width: calc(100% - 250px);
  background-color: #f1f1f1;
  padding: 10px 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
}

.footer-closed {
  width: calc(100% - 55px);
  background-color: #f1f1f1;
  padding: 10px 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
}

.logo {
  height: 40px;
  border-radius: 25px;
}

.notice-snackbar {
  background-color: #ffffff !important;
  padding: 10px 6px !important;
}

.snackbar-username {
  font-size: 15px;
  font-weight: bold !important;
  padding-left: 5px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.snackbar-content {
  font-size: 15px;
  color: #929292;
  padding: 0 5px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.notice-menu {
  padding: 0 !important;
  margin: 2px 12px 10px 12px !important;
  border-radius: 4px !important;
  &:hover {
    margin: 1px 11px 9px 11px !important;
    border: 1px solid #bbbbbb !important;
  }
}

.notice-menu-username {
  font-size: 15px;
  font-weight: bold !important;
  padding-left: 10px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.notice-menu-time {
  font-size: 12px;
  color: #929292;
  display: flex;
  align-items: center;
}

.notice-menu-content {
  font-size: 13px;
  padding: 0 5px 0 10px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news-notice {
  position: absolute;
  font-size: 30px;
  top: -8px;
  right: -8px;
}

.avatar {
  height: 40px;
  margin-right: 20px !important;
  border-radius: 25px;
}

.avatar-closed {
  height: 28px !important;
  width: 28px !important;
}

.avatar-text {
  max-width: 160px; /* 根據您的具體布局調整 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px; /* 根據需要調整 */
}

.list {
  min-width: 230px;
}

.list-closed {
  max-width: 57px;
}

.listitem-user {
  text-align: center !important;
  height: 74px;
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap; /* 保持文字在一行 */
  overflow: hidden; /* 隱藏超過容器的內容 */
  text-overflow: ellipsis; /* 超出部分顯示省略號 */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  max-width: 230px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 12px !important;
  color: #ffffff;
  background-color: rgb(94, 93, 93);
}

.listitem-user-closed {
  color: #ffffff;
  background-color: rgb(94, 93, 93);
}

.listitem-closedicon {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.listitem-news {
  text-align: center !important;
  font-size: 13px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 12px !important;
  padding-right: 40px !important;
}

.listitem-news-closed {
  text-align: center !important;
  font-size: 13px;
  padding: 9px 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 12px !important;
}

.Navicon-news {
  text-align: center;
  font-weight: bold;
  padding-right: 10px;
}

.part-listitem {
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  border-radius: 12px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.part-text {
  text-align: center;
  font-weight: bold;
}

.listitem {
  font-size: 13px;
  line-height: 20px;
  border-radius: 12px !important;
}

.listitem-closed {
  font-size: 13px;
  padding: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 12px !important;
}

.Navicon {
  padding-right: 10px;
}

.drawer-fc {
  padding: 15px 0px !important;
  background-color: #949393 !important;
  color: #ffffff !important;
  border-radius: 0 5px 5px 0 !important;
}

.container {
  flex: 1 !important;
  margin-left: 250px !important;
  width: calc(100% - 250px) !important;
  overflow: auto;
  padding-top: 64px;
  padding-bottom: 40px;
}

.container-closed {
  flex: 1 !important;
  margin-left: 55px !important;
  width: calc(100% - 55px) !important;
  overflow: auto;
  padding-top: 64px;
  padding-bottom: 40px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}