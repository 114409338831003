.main-box {
    padding-bottom: 10px;
    margin: 0;
}

.card {
    border-radius: 15px 0 15px 15px!important;
    border-top: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
}
  
.cardcontent {
    width: 100%;
    height: 100%;
    max-height: 429px;
    padding: 0 !important;
    overflow: auto;
    position: relative;
}

.cardcontent-title-box {
    background-color: #FFFFFF;
    border-bottom: 1px solid #E5E5E5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    top: -8px;
    z-index: 1000;
}

.title-label {
    font-size: 15px !important;
    font-weight: bold !important;
    padding: 10px 16px;
    white-space: nowrap;
    overflow: hidden;
}

.cardcontent-content-box {
    min-height: 336px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 15px 0 !important;
}

.status-color-box {
    animation: slideInLeft 1s ease-in-out forwards;
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.content-grid {
    padding: 10px 0;
    animation: slideInUp 1s ease-in-out forwards;
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.content-info {
    font-size: 14px !important;
    padding: 0 16px;
}

.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #9E9E9E;
    animation: slideInDown 1s ease-in-out forwards;
}

@keyframes slideInDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}