.title-tip {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 19.5px !important;
    color: #777777 !important;
}

.title {
    font-size: 55px !important;
    font-weight: bold !important;
    color: #000000;
}

.description {
    text-align: center;
    font-size: 13px !important;
    line-height: 22.4px !important;
    color: #333 !important;
    max-width: 500px;
}

.log-date {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 28px !important;
    text-align: left;
    color: #62a6e5 !important;
}

.log-version {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 20px !important;
    text-align: left;
    max-width: fit-content !important;
    color: #ffffff !important;
    background-color: #62a6e5 !important;
}

.timeline-connector {
    width: 1px !important;
}

.timeline-dot {
    color: #62a6e5 !important;
    background-color: #62a6e5 !important;
}

.log-title {
    font-size: 22px !important;
    font-weight: bold !important;
    line-height: 32px !important;
    text-align: left;
    color: #353b41 !important;
}

.log-content {
    font-size: 16px !important;
    line-height: 28px !important;
    text-align: left;
    color: #333 !important;
    padding-top: 5px;
}

.log-description-title {
    margin-top: 25px;
}

.log-description-title-text {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 28px !important;
    text-align: left;
    color: #3c3a38 !important;
}

.log-description-description {
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: left;
    color: #333 !important;
    padding-top: 5px;
    padding-left: 24px;
}

.log-description {
    font-size: 15px !important;
    line-height: 20px !important;
    color: #353b41 !important;
    background-color: #ffffff;
    padding: 12px !important;
    border-radius: 10px;
    margin-bottom: 10px !important;
}

/* 新增部分 */

.log-description-add-part {
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 10px;
    border : 1px solid #dde6e2 !important;
    background-color: #f2f8f6;
    box-shadow: 0 0 10px #dde6e2;
}

.grade-icon {
    font-size: 16px !important;
    color: #86c1a1 !important;
    margin-right: 8px;
}

.task-icon {
    font-size: 16px !important;
    color: #86c1a1 !important;
    margin-right: 12px;
}

/* 改進部分 */

.log-description-improve-part {
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 10px;
    border : 1px solid #eddfd7 !important;
    background-color: #fcf3ee;
    box-shadow: 0 0 10px #eddfd7;
}

.settings-icon {
    font-size: 16px !important;
    color: #db996d !important;
    margin-right: 8px;
}

.construction-icon {
    font-size: 16px !important;
    color: #db996d !important;
    margin-right: 12px;
}

/* 修復部分 */
.log-description-fix-part {
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 10px;
    border : 1px solid #e6d2d3 !important;
    background-color: #fceeee;
    box-shadow: 0 0 10px #e6d2d3;
}

.bugreport-icon {
    font-size: 16px !important;
    color: #cf6a69 !important;
    margin-right: 8px;
}

.autofixhigh-icon {
    font-size: 16px !important;
    color: #cf6a69 !important;
    margin-right: 12px;
}

/* 移除部分 */
.log-description-remove-part {
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 10px;
    border : 1px solid #eaecee !important;
    background-color: #f8f9fb;
    box-shadow: 0 0 10px #eaecee;
}

.delete-icon {
    font-size: 16px !important;
    color: #6c7a89 !important;
    margin-right: 8px;
}

.layersclear-icon {
    font-size: 16px !important;
    color: #6c7a89 !important;
    margin-right: 12px;
}

/* .footer {
    background-color: #4d4d4d;
    color: #fff;
    text-align: center;
    padding: 20px 10px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
} */

.default-footer {
    background-color: #4d4d4d;
    color: #fff;
    text-align: center;
    padding: 20px 10px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
    position: fixed;
    bottom: 0;
    width: 100%;
}